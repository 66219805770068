var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-form',{staticClass:"el-form-search",attrs:{"label-width":"70px"}},[_c('el-form-item',{attrs:{"label":"名称："}},[_c('el-input',{attrs:{"size":"small"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('el-form-item',{attrs:{"label":" ","label-width":"25px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.getList}},[_vm._v("搜索")])],1)],1),_c('el-table',{attrs:{"data":_vm.list,"header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"width":"250","label":"用户信息","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"user-info"},[_c('el-image',{attrs:{"src":row.avatar ? row.avatar : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/userDefault.png'}}),_c('span',[_vm._v(_vm._s(row.name || '--'))]),_c('el-tag',{staticStyle:{"margin-left":"5px"},attrs:{"size":"small","type":"success","effect":"plain"}},[_vm._v("企微好友")])],1)]}}])}),_c('el-table-column',{attrs:{"label":"联系人类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.type == 1 ? '微信用户' : '企业微信用户')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"性别","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.gender == 0 ? '未知' : row.gender == 1 ? '男' : '女')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"用户标签","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.external_contact_tag),function(item,index){return _c('span',{key:index,staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(item))])})}}])}),_c('el-table-column',{attrs:{"label":"加入方式","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.external_contact_addway ? row.external_contact_addway[0] : '--')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"create_time","label":"创建时间","align":"center","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getDateformat(row.create_time))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"170","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){(_vm.showSendMessage = !0), (_vm.external_userid = row.external_userid)}}},[_vm._v("联系"+_vm._s(row.gender == 1 || row.gender == 0 ? '他' : '她'))]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$router.push({ path: '/user/enterpriseWechat/sendLog', query: { external_userid: row.external_userid } })}}},[_vm._v("发送记录")])]}}])})],2),(_vm.showSendMessage)?_c('SendMessage',{attrs:{"external_userid":_vm.external_userid}}):_vm._e(),_c('Paging',{staticClass:"paging",attrs:{"total":_vm.total,"page":_vm.page,"pageNum":_vm.rows},on:{"updatePageNum":_vm.updateData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }